import { render, staticRenderFns } from "./Fields.vue?vue&type=template&id=15123acc&scoped=true&"
import script from "./Fields.vue?vue&type=script&lang=js&"
export * from "./Fields.vue?vue&type=script&lang=js&"
import style0 from "./Fields.vue?vue&type=style&index=0&id=15123acc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15123acc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VForm,VSelect,VSkeletonLoader,VSwitch})
